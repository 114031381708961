import React, { useEffect, useState } from 'react';
import CardHoc from "../Hoc/cardHoc";
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import { dateToPtBr } from '../Basicos/funcoes';
import { FiTrash2 } from 'react-icons/fi';
import Select from 'react-select'
import { axiosApi } from '../Basicos/axiosInstances';

function ModalEditarConteudo2({
    isOpen,
    dados,
    setConteudoDisponivel,
    conteudoDisponivel,
    closeModal,
    setDadosModalConteudo,
    dadosModalConteudo
}) {
    const [dadosModalTemp, setDadosModalTemp] = useState([]);
    const [conteudoDisponivelTemp, setConteudoDisponivelTemp] = useState([]);
    const acoes = {
        "criar": true,
        "editar": true,
        "deletar": true
    }

    useEffect(() => {
        if (isOpen) {
            setDadosModalTemp(JSON.parse(JSON.stringify(dados)));
            setConteudoDisponivelTemp(conteudoDisponivel);
        }
    }, [isOpen]);

    const removerAula = (index) => {
        const novaAula = {
            value: dadosModalTemp.conteudo[index].conteudo_aula_id,
            label: dadosModalTemp.conteudo[index].titulo,
            completo: dadosModalTemp.conteudo[index]
        };

        setConteudoDisponivelTemp((prevAulasDisponiveis) => ({
            ...prevAulasDisponiveis,
            [dadosModalTemp?.tipo_atividade_id]: [
                ...(prevAulasDisponiveis?.[dadosModalTemp?.tipo_atividade_id] || []),
                novaAula,
            ],
        }));
        const novoConteudo = dadosModalTemp.conteudo.filter((_, i) => i !== index);
        setDadosModalTemp((prevState) => ({
            ...prevState,
            conteudo: novoConteudo,
        }));
    }

    const close = () => {
        setDadosModalTemp([]);
        setConteudoDisponivelTemp([]);
        closeModal();
    }

    const salvar = async () => {
        const { adicionados, removidos } = calcularDiferencas(dados.conteudo, dadosModalTemp.conteudo);
        let dado = {
            conteudo_prova_id: dados.conteudo_prova_id,
            prova_id: dados.horario_aula_prova_id,
            semestre_id: dados.semestre_id,
            periodo_letivo_id: dados.periodo_letivo_id,
            turma_id: dados.turma_id,
            adicionados: adicionados,
            removidos: removidos,
        }

        const response = await axiosApi.put(`semana-padrao/cadastro-de-ofertas/conteudo-prova/${dados.conteudo_prova_id}`, dado);

        const novoDadosModalConteudo = dadosModalConteudo.map((elemento) => {
            if (elemento.horario_aula_prova_id === dadosModalTemp.horario_aula_prova_id) {
                return {
                    ...elemento,
                    conteudo: dadosModalTemp.conteudo,
                };
            }
            return elemento;
        });
        setDadosModalConteudo(novoDadosModalConteudo);
        setConteudoDisponivel(conteudoDisponivelTemp);
        closeModal();
    }

    const calcularDiferencas = (conteudoOriginal, conteudoAtualizado) => {
        const originalMap = new Map(conteudoOriginal.map(item => [item.conteudo_aula_id, item]));
        const atualizadoMap = new Map(conteudoAtualizado.map(item => [item.conteudo_aula_id, item]));

        const adicionados = conteudoAtualizado.filter(item => !originalMap.has(item.conteudo_aula_id));
        const removidos = conteudoOriginal.filter(item => !atualizadoMap.has(item.conteudo_aula_id));

        return { adicionados, removidos };
    };

    const selecionarAula = (value) => {
        const novoConteudo = value.completo;
        const novoConteudoArray = [...dadosModalTemp.conteudo, novoConteudo];

        novoConteudoArray.sort((a, b) => {
            let dataA = new Date(a.datas[0]);
            let dataB = new Date(b.datas[0]);
            return dataA - dataB;
        });

        setDadosModalTemp((prevState) => ({
            ...prevState,
            conteudo: novoConteudoArray,
        }));

        setConteudoDisponivelTemp((prevAulasDisponiveis) => {
            const novasAulas = prevAulasDisponiveis[dadosModalTemp.tipo_atividade_id]?.filter(
                (aula) => aula.value !== novoConteudo.conteudo_aula_id
            );

            return {
                ...prevAulasDisponiveis,
                [dadosModalTemp.tipo_atividade_id]: novasAulas,
            };
        });
    };

    return (
        <Modal isOpen={isOpen} size={'xl'}>
            <ModalBody className="modal-editar-conteudo">
                <Col sm={12} className="align-content-middle mb-4 mt-2">
                    <h3><b>Conteúdo da Prova {dados.titulo_prova} - {dados.data_prova}</b></h3>
                </Col>
                <Row className="filtro-modal-conteudo">
                    <Select
                        noOptionsMessage={() => 'Sem opções'}
                        defaultValue={[]}
                        value={null}
                        placeholder={"Adicionar Conteúdos"}
                        options={conteudoDisponivelTemp?.[dados.tipo_atividade_id]}
                        onChange={(value) => selecionarAula(value)}
                        className={"select select-form"}
                    />
                </Row>
                <Row className="mt-2">
                    <Col md={2}>
                        <h5><b>Data</b></h5>
                    </Col>
                    <Col md={5}>
                        <h5><b>Aula</b></h5>
                    </Col>
                    <Col md={3}>
                        <h5><b>Professores</b></h5>
                    </Col>
                    <Col md={2}>
                    </Col>
                </Row>
                {
                    dadosModalTemp?.conteudo?.map((conteudo, index) => {
                        return <>
                            <Row className="mt-2 linha-conteudo-prova">
                                <Col md={3} className="coluna-data-modal">
                                    <h6 className="modal-conteudo-edit">{
                                        conteudo?.datas.map((data, index) => {
                                            return <>
                                                {`${conteudo?.datas.length - 1 == index ? dateToPtBr(data) : `${dateToPtBr(data)}, `}`}
                                            </>
                                        })
                                    }</h6>
                                </Col>
                                <Col md={5}>
                                    <h6 className="modal-conteudo-edit">{conteudo?.titulo}</h6>
                                </Col>
                                <Col md={3}>
                                    <h6 className="modal-conteudo-edit">{conteudo?.professores}</h6>
                                </Col>
                                <Col md={1}>
                                    <FiTrash2 onClick={() => removerAula(index)} size={"25px"} className="icon-remover-list menuIcons" style={{ cursor: "pointer" }} />
                                </Col>
                            </Row>
                        </>
                    })
                }
                <Row>
                    <Col className="align-content-middle mt-4">
                        <Button className="mx-3" onClick={() => close()}>Cancelar</Button>
                        <Button className="mx-3" onClick={() => salvar()}>Salvar</Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        ativarCofirmacao: (obj) => dispatch(actionsRedux.ativarModalConfirm(obj)),
        atualizarDadosRemotamente: () => dispatch(actionsRedux.atualizarSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade)),
    }
}

export default connect(null, mapDispatchToProps)(CardHoc(ModalEditarConteudo2));