import React, { Fragment, useEffect, useState } from 'react';
import CardHoc from "../Hoc/cardHoc";
// import CardHoc from "../../Hoc/cardHoc";
import MenuTable from '../Menu/menuTable';
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import { Button, Col, Modal, ModalBody } from 'reactstrap';
import { Tabela } from '../Basicos';
import ModalEditarConteudo2 from './ModalEditarConteudo2';

function montarColunas({ editar }, openModal) {
    let campos2 = [
        { label: 'Data', name: 'data_prova', type: 'dataBr' },
        { label: 'Título', name: 'titulo_prova' },
        { label: 'Tipo de Atividade', name: 'tipo_atividade' },
        { label: 'Qtd Conteúdo', name: 'conteudo' },
    ]
    const colunas = campos2.map((elemento) => {
        switch (elemento.name) {
            case "cor":
                return {
                    Header: elemento.label,
                    Cell: ({ row }) => (
                        <div style={{ background: row.original.cor, width: -1, height: 32, borderRadius: 100 }} />)
                };
            default:
                return {
                    Header: elemento.label,
                    accessor: elemento.name,
                }
        }
    });

    if (editar) {
        colunas.push({
            Cell: ({ row }) => (
                <MenuTable
                    iconEdit={editar}
                    editarOnClick={() => openModal(row.original)}
                />
            ),
            resizable: false,
            accessor: "acoes",
            style: { width: 64 }
        });
    }
    return colunas;
}

function ModalEditarConteudo({
    isOpen,
    dadosModalConteudo,
    setDadosModalConteudo,
    conteudoDisponivel,
    setConteudoDisponivel,
    setModalConteudo
}) {
    const [aulasDisponiveis, setAulasDisponiveis] = useState([]);
    const [modal, setModal] = useState(false);
    const [dadosModal, setDadosModal] = useState([]);
    const acoes = {
        "criar": true,
        "editar": true,
        "deletar": true
    }

    const toggle = () => {
        setModalConteudo(false)
    }

    const openModal = (prova = null) => {
        setDadosModal(prova)
        setModal(true);
    }

    const closeModal = () => {
        setModal(false);
    }

    const colunas = React.useMemo(() => montarColunas(acoes, openModal), [dadosModalConteudo])
    return (
        <Modal isOpen={isOpen} modalClassName="custom-modal" toggle={toggle}>
            <ModalBody>
                <Col sm={12} className="align-content-middle mb-2 mt-2">
                    <h3><b>Provas - {dadosModalConteudo?.[0]?.periodo_letivo} - {dadosModalConteudo?.[0]?.semestre} Semestre {dadosModalConteudo?.[0]?.turma != null ? `- ${dadosModalConteudo?.[0]?.turma}` : ""}</b></h3>
                </Col>
                <Tabela
                    columns={colunas}
                    data={dadosModalConteudo}
                    descricaoConteudo={true}
                />
                <Button className="mx-3 btn-fechar-modal" onClick={() => toggle()}>Fechar</Button>
                {modal ?
                    <ModalEditarConteudo2
                        isOpen={modal}
                        closeModal={closeModal}
                        dados={dadosModal}
                        setConteudoDisponivel={setConteudoDisponivel}
                        conteudoDisponivel={conteudoDisponivel}
                        setDadosModalConteudo={setDadosModalConteudo}
                        dadosModalConteudo={dadosModalConteudo}
                    /> : ""
                }
            </ModalBody>
        </Modal>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        ativarCofirmacao: (obj) => dispatch(actionsRedux.ativarModalConfirm(obj)),
        atualizarDadosRemotamente: () => dispatch(actionsRedux.atualizarSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade)),
    }
}

export default connect(null, mapDispatchToProps)(CardHoc(ModalEditarConteudo));