import React, { useState, useEffect, Fragment } from 'react'
import { Card, Row, Col, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { axiosApi } from '../Basicos/axiosInstances';
import { connect } from 'react-redux';
import { NewLoader, TableWithVirtualizedRow } from '../Basicos';
import * as actionsRedux from './../Store/Actions/index';
import ModalAgruparAlunos from '../Basicos/Modal/ModalAgruparAlunos';
import ModalPeriodoCurso from '../Modal/ModalPeriodoCurso';
import ModalAlertGrupos from '../Modal/ModalAlertGrupos';
import ModalAtualizarAula from '../Modal/ModalAtualizarAula';

function AssociadorDeGruposProfessor({
    ativar,
    curso,
    setCursoId,
    periodoLetivo,
    setPeriodoLetivoId,
    subFuncionalidade: {
        url
    }
}) {
    const [semestres, setSemestres] = useState([]);

    const [semestreId, setSemestreId] = useState();

    const [semetreDescricao, setSemetreDescricao] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [periodosLetivos, setPeriodosLetivos] = useState([]);

    const [cursos, setCursos] = useState([]);

    const [GruposAssociados, setGruposAssociados] = useState();

    const [professores, setProfessores] = useState();

    const [professoresIds, setProfessoresIds] = useState([])

    const [grupos, setGrupos] = useState();

    const [grupos2, setGrupos2] = useState([]);

    const [grupos3, setGrupos3] = useState([]);

    const [locais, setLocais] = useState([]);

    //modais
    const [modal, setModal] = useState(false);
    const [modalAgrupar, setModalAgrupar] = useState(false); // Remover Se Nao Quebrar
    const [modalAdicao, setModalAdicao] = useState(false);

    const semestresDoCursoSelecionado = () => semestres.filter(semestre => semestre.curso_id === curso);

    const cursoSelecionado = () => cursos.find(c => c.id === curso);

    const periodoLetivoSelecionado = () => periodosLetivos.find(pL => pL.id === periodoLetivo);
    const toggleModal = () => setModal(!modal);

    const gerar = (semestre_id) => {
        setIsLoading(true);
        axiosApi({
            method: 'get',
            url,
            params: {
                semestre_id,
                periodo_letivo_id: periodoLetivo,
            }
        }).then(resp => {
            setProfessores(resp.data.professores);
            setSemetreDescricao(resp.data.semestre)
            setGrupos(resp.data.grupo)
            setGrupos2(resp.data.grupo2)
            setGrupos3(resp.data.grupo3)
            setLocais(resp.data.locais)
            setModalAdicao(true)
        }).catch(error => {
            if (error.response && error.response.data.erro)
                ativar(error.response.status, error.response.data.erro);
        }).finally(() => {
            setIsLoading(false);
        });
    }
    //Requisição inicial 
    useEffect(() => {
        axiosApi.get(url).then(resp => {
            setSemestres(resp.data.semestre);
            setPeriodosLetivos(resp.data.periodo);
            setGruposAssociados(resp.data.GruposAssociados);
            setCursos(resp.data.cursos);
            setProfessores(resp.data.professores);
        }).catch(error => {
            if (error.response.status === 403) {
                ativar(400, error?.response?.data?.mensagem);
            }
        });
    }, [url]);

    const SemestreItem = ({ semestre }) => {
        return (
            <Col className="mt-5">
                <Card className="d-flex py-5 ofertaPeriodo__card">
                    <span className="font-weight-bold azul-principal mx-auto mb-3 ofertaPeriodo__card--titulo">
                        {semestre.descricao}
                    </span>
                    <Button
                        className="font-weight-bold mx-auto"
                        onClick={() => gerar(semestre.id, professores)}
                    >
                        Visualizar
                    </Button>
                </Card>
            </Col>
        );
    }

    const CardComSemestres = () => {
        return (
            <Card className="mt-3 px-5 pb-5">
                <Row xs="1" sm="2" md="3" lg="4">
                    {
                        semestresDoCursoSelecionado().map(semestre => <SemestreItem semestre={semestre} key={semestre.id} />)
                    }
                </Row>
            </Card>
        );
    }

    const Titulo = () => {
        return (
            <h1 className="m-0 font-weight-bold label-nome-pagina">
                {periodoLetivoSelecionado()?.descricao} - {cursoSelecionado()?.nome}
                <Button onClick={toggleModal} className="float-right">
                    Editar
                </Button>
            </h1>
        );
    }
    return (
        <NewLoader isActive={isLoading} overlay >
            {
                (periodosLetivos && cursos && GruposAssociados && periodoLetivo && curso) &&
                <Fragment>
                    <Titulo />
                    <CardComSemestres />
                </Fragment>

            }
            {
                (periodosLetivos && cursos) &&
                <ModalPeriodoCurso
                    isOpen={modal}
                    setIsOpen={setModal}
                    cursos={cursos}
                    periodosLetivos={periodosLetivos}
                    cursoId={curso}
                    setCursoId={setCursoId}
                    periodoLetivoId={periodoLetivo}
                    setPeriodoLetivoId={setPeriodoLetivoId}
                />

            }
            {
                <ModalAdição
                    ativar={ativar}
                    grupos={grupos}
                    grupos2={grupos2}
                    grupos3={grupos3}
                    locais={locais}
                    professores={professores}
                    setProfessores={setProfessores}
                    modalAdicao={modalAdicao}
                    setModalAdicao={setModalAdicao}
                    semestre={semetreDescricao}
                    professoresIds={professoresIds}
                    setProfessoresIds={setProfessoresIds}
                    modalAgrupar={modalAgrupar}
                    setModalAgrupar={setModalAgrupar}
                    cursoId={curso}
                    semestreId={semestreId}
                    setSemestreId={setSemestreId}
                    periodoLetivo={periodoLetivo}
                    setIsLoading={setIsLoading}
                    setModal={setModal}
                />
            }
        </NewLoader>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        ativarCofirmacao: (obj) => dispatch(actionsRedux.ativarModalConfirm(obj)),
        adicionarOnClick: (objeto) => dispatch(actionsRedux.adicionarSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade, objeto)),
        setCursoId: (tipo, acao) => dispatch(actionsRedux.setCurso(tipo, acao)),
        setPeriodoLetivoId: (tipo, acao) => dispatch(actionsRedux.setPeriodoLetivo(tipo, acao))
    }
}

const mapStateToProps = (state) => {
    return {
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
        curso: state.auth.cursoId,
        periodoLetivo: state.auth.periodoLetivoId
    }
}

function ModalAdição({
    ativar,
    professores,
    grupos,
    grupos2,
    grupos3,
    locais,
    semestre,
    setProfessores,
    professoresIds,
    setProfessoresIds,
    modalAdicao,
    setModalAdicao,
    modalAgrupar,
    setModalAgrupar,
    cursoId,
    semestreId,
    setSemestreId,
    periodoLetivo,
    setIsLoading,
    setModal
}) {

    const cha = grupos != undefined ? grupos.slice() : [];
    const ace = grupos != undefined ? grupos.slice() : [];
    const [modalCritica, setModalCritica] = useState(false);
    const [modalAtualizar, setModalAtualizar] = useState(false);
    const [textoModalCritica, setTextoModalCritica] = useState(false);
    const [aceFaltando, setAceFaltando] = useState();
    const [chaFaltando, setChaFaltando] = useState();
    const [tutFaltando, setTutFaltando] = useState();

    const setFiltro = (e) => {
        console.log(e)
    }

    const gruposOptionsAce = () => {
        var contemSubgrupo = false;
        var contemGrupo = false;
        professores.forEach(professor => {
            professor.grupo_ace.forEach(grupoId => {
                const grupoIndex = ace.findIndex(element => element.id === grupoId);
                if (grupoIndex !== -1 && ace[grupoIndex].id !== ace[grupoIndex].grupo_principal_id) {
                    const grupoPrincipalIndex = ace.findIndex(element => element.id === ace[grupoIndex].grupo_principal_id);
                    if (grupoPrincipalIndex !== -1) {
                        ace.splice(grupoPrincipalIndex, 1);
                    }
                    contemSubgrupo = true;
                } else if (grupoIndex !== -1 && ace[grupoIndex].id == ace[grupoIndex].grupo_principal_id) {
                    contemGrupo = true;
                }
            });
        });

        const gruposNaoAtribuidos = ace.filter(grupo => {
            return (
                (!professores.some(professor => professor.grupo_ace && professor.grupo_ace.includes(grupo.id))) &&
                (grupo.grupo_principal_id == grupo.id ||
                    !professores.some(professor => professor.grupo_ace && professor.grupo_ace.includes(grupo.grupo_principal_id)))
            );
        });

        var gruposOptions = gruposNaoAtribuidos;
        if (contemSubgrupo)
            gruposOptions = gruposNaoAtribuidos.filter(item => item.id != item.grupo_principal_id);
        else if (contemGrupo)
            gruposOptions = gruposNaoAtribuidos.filter(item => item.id == item.grupo_principal_id);

        const options = gruposOptions.map(grupo => ({
            value: grupo.id,
            label: grupo.descricao,
            tipo: grupo.tipo_de_grupo_id,
            grupo_principal_id: grupo.grupo_principal_id
        }));

        return options;
    };

    const gruposOptionsCha = () => {
        var contemSubgrupo = false;
        var contemGrupo = false;
        professores.forEach(professor => {
            professor.grupo_cha.forEach(grupoId => {
                const grupoIndex2 = cha.findIndex(element => element.id === grupoId);
                if (grupoIndex2 !== -1 && cha[grupoIndex2].id !== cha[grupoIndex2].grupo_principal_id) {
                    const grupoPrincipalIndex2 = cha.findIndex(element => element.id === cha[grupoIndex2].grupo_principal_id);
                    if (grupoPrincipalIndex2 !== -1) {
                        cha.splice(grupoPrincipalIndex2, 1);
                    }
                    contemSubgrupo = true;
                } else if (grupoIndex2 !== -1 && cha[grupoIndex2].id == cha[grupoIndex2].grupo_principal_id) {
                    contemGrupo = true;
                }
            });
        });

        const gruposNaoAtribuidos = cha.filter(grupo => {
            return (
                (!professores.some(professor => professor.grupo_cha && professor.grupo_cha.includes(grupo.id))) &&
                (grupo.grupo_principal_id == grupo.id ||
                    !professores.some(professor => professor.grupo_cha && professor.grupo_cha.includes(grupo.grupo_principal_id)))
            );
        });

        var gruposOptions = gruposNaoAtribuidos;
        if (contemSubgrupo)
            gruposOptions = gruposNaoAtribuidos.filter(item => item.id != item.grupo_principal_id);
        else if (contemGrupo)
            gruposOptions = gruposNaoAtribuidos.filter(item => item.id == item.grupo_principal_id);

        const options = gruposOptions.map(grupo => ({
            value: grupo.id,
            label: grupo.descricao,
            tipo: grupo.tipo_de_grupo_id,
            grupo_principal_id: grupo.grupo_principal_id
        }));

        return options;
    };

    const gruposOptionsTut = () => {
        const gruposNaoAtribuidos = grupos2.filter(grupo => {
            return !professores.some(professor => professor.tutoria_id && professor.tutoria_id == grupo.id);
        });

        var newObj = {
            id: 0,
            descricao: 'Selecione',
            tipo_de_grupo_id: 0,
            curso_id: 0,
            grupo_principal_id: 0
        }

        gruposNaoAtribuidos.unshift(newObj);

        const options = gruposNaoAtribuidos.map(grupo => ({
            value: grupo.id,
            label: grupo.descricao,
            tipo: grupo.tipo_de_grupo_id,
            grupo_principal_id: grupo.grupo_principal_id
        }));

        return options;
    }

    const locaisOptions = () => {
        var newObj = {
            id: 0,
            nome: 'Selecione',
            tipo_de_grupo_id: 0,
            curso_id: 0,
            grupo_principal_id: 0
        }

        if (locais[0]?.nome != 'Selecione')
            locais.unshift(newObj);

        return locais.map(locais => ({ value: locais.id, label: locais.nome }));
    }

    const gruposOptions = () => grupos.map(grupos => ({ value: grupos.id, label: grupos.descricao }));

    const grupos2Options = () => grupos2.map(grupos2 => ({ value: grupos2.id, label: grupos2.descricao }));

    // const locaisOptions = () => locais.map(locais => ({ value: locais.id, label: locais.nome }))

    const updateGrupoProfessores = (objeto) => {
        const professoresPrev = professores.slice()
        const professorIndex = professoresPrev.findIndex(professor => professor.id == objeto.row_id)
        professoresPrev[professorIndex] = {
            ...professoresPrev[professorIndex],
            [objeto.campo]: objeto.valor
        }
        setProfessores(professoresPrev)
    }

    const closeModalCritica = (valor) => {
        setModalCritica(valor)
    }

    const atualizar = async (atualizarHoje) => {
        try {
            setIsLoading(true);
            var novoObj = {
                professores: professores,
                periodo_letivo_id: periodoLetivo,
                atualizar_hoje: atualizarHoje
            }
            const response = await axiosApi.put(`professores/grupos-de-professores/1`, novoObj);
            if (response.data.data.tem_critica) {
                setModalCritica(response.data.data.tem_critica)
                setTextoModalCritica(response.data.data.texto)
                setAceFaltando(gruposOptionsAce);
                setChaFaltando(gruposOptionsCha);
                setTutFaltando(gruposOptionsTut);
            }
            ativar(201, 'Grupo Atualizado com Sucesso!')
        } catch (error) {
            ativar(400, error?.response?.data[0] ?? error?.response?.data?.error)
            console.log(error?.response?.data)
        } finally {
            setModal(false);
            setIsLoading(false);
        }
    }

    const regex = /^([A-Za-z]{1})(\d{1,2})|(^$)$/

    const atualizarAula = () => {
        setModalAtualizar(true);
    }

    const verificarAtualizacao = (atualizarHoje) => {
        setModalAtualizar(false);
        atualizar(atualizarHoje);
    }

    const getProfessoresIdFromTable = (acao, objeto) => {
        const { id, semestre_id } = objeto
        if (acao === 'add') {
            setProfessoresIds([...professoresIds, id])
            setSemestreId(semestre_id)
        }
        if (acao === 'remove') {
            const prevProfessoresIds = professoresIds.filter(professorId => professorId !== id)
            setProfessoresIds(prevProfessoresIds)
        }
    }

    const criarGruposProfessores = async (objeto) => {
        try {
            const response = await axiosApi.post(`professores/grupos-de-professores/`, objeto)
            ativar(response.status, response.data.sucesso)
        } catch (error) {
            console.log(error)
            ativar(400, 'Já Existe professor com o grupo referido')
        }
    }

    return (
        <Modal isOpen={modalAdicao} size="lg" id="modal-professor-grupo">
        <ModalAtualizarAula isOpen={modalAtualizar} atualizarAula={verificarAtualizacao} />
            <ModalAlertGrupos
                isOpen={modalCritica}
                titulo={'Aviso'}
                mensagem={textoModalCritica}
                setIsOpen={closeModalCritica}
                cha={chaFaltando}
                ace={aceFaltando}
                tut={tutFaltando}
            />
            <ModalHeader className="modal-content">
                <p className="m-0 font-weight-bold nome_modal" a> S{semestre.descricao} -  {semestre?.cursos?.abreviatura}</p>
            </ModalHeader >
            <ModalBody>
                <div className='div-de-teste'>
                    <TableWithVirtualizedRow
                        tabelaClassName="tabelaConsulta"
                        updateMyData={updateGrupoProfessores}
                        getAlunosIdFromTable={getProfessoresIdFromTable}
                        alunosIds={professoresIds}
                        isGrupoProfessor={true} //variavel para saber se vem da tela de professor
                        columns={[
                            {
                                Header: 'Nome',
                                accessor: 'nome',
                                disabled: true,
                                style: { width: '250px' },
                                id: 'nome_professor',
                            },
                            {
                                Header: 'CPF',
                                accessor: 'cpf',
                                disabled: true,
                                style: { width: '150px' },

                            },
                            {
                                Header: 'Grupo ACE',
                                accessor: 'grupo_ace',
                                id: 'grupo_ace',
                                inputType: 'newSelect',
                                style: { width: '1050px' },
                                selectOptions: grupos && gruposOptions(),
                                selectOptionsDisponivel: grupos && gruposOptionsAce()
                                // regex: regex,
                            },
                            {
                                Header: 'Local ACE',
                                accessor: 'local_ace',
                                id: 'local_ace',
                                inputType: 'select',
                                style: { width: '200px' },
                                selectOptions: locais && locaisOptions(),
                                selectOptionsDisponivel: locais && locaisOptions()
                                // regex: regex,
                            },
                            {
                                Header: 'Grupo CHA',
                                accessor: 'grupo_cha',
                                id: 'grupo_cha',
                                inputType: 'newSelect',
                                style: { width: '1050px' },
                                selectOptions: grupos && gruposOptions(),
                                selectOptionsDisponivel: grupos && gruposOptionsCha()
                                // regex: regex,
                            },
                            {
                                Header: 'Local CHA',
                                accessor: 'local_cha',
                                id: 'local_cha',
                                inputType: 'select',
                                selectOptions: locais && locaisOptions(),
                                selectOptionsDisponivel: locais && locaisOptions(),
                                style: { width: '200px' },
                            },
                            {
                                Header: 'Grupo Tutoria',
                                accessor: 'tutoria_id',
                                id: 'tutoria_id',
                                inputType: 'select',
                                selectOptions: grupos2 && grupos2Options(),
                                selectOptionsDisponivel: grupos2 && gruposOptionsTut(),
                                style: { width: '200px' },
                            },
                        ]}
                        data={professores}
                        selectOptionsDisponivel={grupos && gruposOptions()}
                        setFiltro={setFiltro}
                    />
                </div>
                <Button onClick={() => (setModalAdicao(false), setProfessoresIds([]))} className="float-left btn-transparente mt-2">Fechar</Button>
                <Button onClick={() => atualizarAula()} color="primary" className="float-right mt-2">Salvar</Button>
            </ModalBody>
            <ModalAgruparAlunos
                isOpen={modalAgrupar}
                setIsOpen={setModalAgrupar}
                alunosIds={professoresIds}
                setAlunosIds={setProfessoresIds}
                selectOptions={grupos2Options()}
                setModalAdicao={setModalAdicao}
                criarGruposAlunos={criarGruposProfessores}
                cursoId={cursoId}
                semestreId={semestreId}
            />
        </Modal>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AssociadorDeGruposProfessor)