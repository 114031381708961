import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from 'react-dom';
import { Input, Button } from 'reactstrap';
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { dateAndHour, dateToPtBr } from "./funcoes";

function GlobalFilter({ globalFilter, setGlobalFilter, pesquisarGlobal = false }) {
    const [mounted, setMount] = useState(false);

    useEffect(() => {
        setMount(true);
    }, []);

    if (pesquisarGlobal && mounted) {
        const dom = document.getElementById('pesquisar-wrap');
        return ReactDOM.createPortal(
            <Input
                type={"text"}
                name={"pesquisa"}
                placeholder={"Pesquisar"}
                value={globalFilter || ''}
                onChange={e => { setGlobalFilter(e.target.value || undefined) }}
            />
            , dom);
    }
    return null;
}

function Tabela({ columns, data, pesquisarGlobal, tabelaClassName = "table-default", RowComponent, descricaoConteudo = false }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize, globalFilter },
        setGlobalFilter,
        rows
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        usePagination
    );

    function BotoesPaginacaoNext(props) {
        return (
            <Fragment>
                <BotaoPaginacao
                    className='-next'
                    style={{ marginRight: '3px' }}
                    buttonStyle={{ marginRight: '5px' }}
                    disabled={!canNextPage}
                    onClick={() => nextPage()}
                >
                    {'>'}
                </BotaoPaginacao>
                <BotaoPaginacao
                    className='-next'
                    disabled={!canNextPage}
                    onClick={() => gotoPage(pageCount - 1)}
                >
                    {'>>'}
                </BotaoPaginacao>
            </Fragment>
        );
    }

    function BotoesPaginacaoPrevious(props) {
        return (
            <Fragment>
                <BotaoPaginacao
                    className='-previous'
                    style={{ marginRight: '3px' }}
                    buttonStyle={{ marginRight: '5px' }}
                    disabled={!canPreviousPage}
                    onClick={() => gotoPage(0)}
                >
                    {'<<'}
                </BotaoPaginacao>
                <BotaoPaginacao
                    className='-previous'
                    disabled={!canPreviousPage}
                    onClick={() => previousPage()}
                >
                    {'<'}
                </BotaoPaginacao>
            </Fragment>
        );
    }

    function registroTotal(pageSize, pageIndex, totalData) {
        const total = (pageSize * pageIndex) > totalData ? totalData : (pageSize * pageIndex);
        return total;
    }

    return (
        <div style={{ width: '100%', padding: "0 1rem" }}>
            <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                pesquisarGlobal={pesquisarGlobal}
            />
            {data && data.length > 0 ? (
                <Fragment>
                    <table {...getTableProps()} className={tabelaClassName}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps({
                                            className: `${column.className} ${tabelaClassName}__celulaCabecalho`,
                                            style: column.style,
                                        })}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return RowComponent ? (
                                    <RowComponent key={i} row={row} prepareRow={prepareRow} />
                                ) : (
                                    <tr {...row.getRowProps()} className={`${descricaoConteudo ? "modal-descricao-conteudo" : ""}`}>
                                        {row.cells.map(cell => {
                                            const switchTypeOrId = cell?.column?.type !== undefined ? cell.column.type : cell.column.id;
                                            switch (switchTypeOrId) {
                                                case "prova.data":
                                                case "datas":
                                                    return (
                                                        <td {...cell.getCellProps({
                                                            className: `${cell.column.className} ${tabelaClassName}__celulaDados`,
                                                            style: cell.column.style,
                                                            default: "N/A"
                                                        })}>
                                                            {dateToPtBr(cell.value)}
                                                        </td>
                                                    );
                                                case "conteudo":
                                                    return (
                                                        <td {...cell.getCellProps({
                                                            className: `${cell.column.className} ${tabelaClassName}__celulaDados`,
                                                            style: cell.column.style,
                                                            default: "N/A"
                                                        })}>
                                                            {cell.value.length}
                                                        </td>
                                                    );
                                                case "data":
                                                case "data_inicio":
                                                case "data_fim":
                                                    return (
                                                        <td {...cell.getCellProps({
                                                            className: `${cell.column.className} ${tabelaClassName}__celulaDados`,
                                                            style: cell.column.style,
                                                            default: "N/A"
                                                        })}>
                                                            {cell.value !== undefined ? dateToPtBr(cell.value) : "N/A"}
                                                        </td>
                                                    )
                                                case "data_envio":
                                                    return (
                                                        <td {...cell.getCellProps({
                                                            className: `${cell.column.className} ${tabelaClassName}__celulaDados`,
                                                            style: cell.column.style,
                                                            default: "N/A"
                                                        })}>
                                                            {cell.value !== undefined ? dateAndHour(cell.value) : "N/A"}
                                                        </td>
                                                    )
                                                case "dataAndHour":
                                                    return (
                                                        <td {...cell.getCellProps({
                                                            className: `${cell.column.className} ${tabelaClassName}__celulaDados`,
                                                            style: cell.column.style,
                                                            default: "N/A"
                                                        })}>
                                                            {cell.value !== undefined ? dateAndHour(cell.value) : "N/A"}
                                                        </td>
                                                    )
                                                case "arrayData":
                                                    return (
                                                        <td {...cell.getCellProps({
                                                            className: `${cell.column.className} ${tabelaClassName}__celulaDados`,
                                                            style: cell.column.style,
                                                            default: "N/A"
                                                        })}>
                                                            {cell.value !== undefined ? cell.value.split(" | ").map((item, index, array) => {
                                                                if (index === array.length - 1) {
                                                                    return dateToPtBr(item);
                                                                } else {
                                                                    return dateToPtBr(item) + ' | ';
                                                                }
                                                            }) : "N/A"}
                                                        </td>
                                                    );
                                                case "conteudo":
                                                    return (
                                                        <td {...cell.getCellProps({
                                                            className: `${cell.column.className} ${tabelaClassName}__celulaDados`,
                                                            style: cell.column.style,
                                                            default: "N/A"
                                                        })}>
                                                            {
                                                                cell.value.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            {`${cell.value.length - 1 == index ? item.titulo : `${item.titulo}, `}`}
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </td>
                                                    )
                                                case "qtd_alocada":
                                                    return (
                                                        <td {...cell.getCellProps({
                                                            className: `${cell.column.className} ${tabelaClassName}__celulaDados`,
                                                            style: cell.column.style,
                                                            default: "N/A"
                                                        })}>
                                                            {cell.value}
                                                        </td>
                                                    )
                                                default:
                                                    return (
                                                        <td {...cell.getCellProps({
                                                            className: `${cell.column.className} ${tabelaClassName}__celulaDados`,
                                                            style: cell.column.style,
                                                            default: "N/A"
                                                        })}>
                                                            {cell.value || cell.column.id === "acoes" || cell.column.id === "Cor" || cell.column.id === "Grupo 1" || cell.column.id === "Grupo 2" || cell.column.id === "Professor"
                                                                ? cell.render('Cell')
                                                                : "N/A"}
                                                        </td>
                                                    )
                                            }
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    <div className="align-content-middle mt-2">
                        <div className="paginacao ReactTable -pagination">
                            <BotoesPaginacaoPrevious />
                            <div className="-center">
                                <span style={{ margin: '3px 10px', fontSize: '1rem' }}>
                                    <span>Pagina </span> {' '}
                                    <span>
                                        <Input
                                            type="number"
                                            value={pageIndex + 1}
                                            onChange={e => {
                                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                                gotoPage(page);
                                            }}
                                            style={{ width: '100px', display: 'inline-block', textAlign: 'center' }}
                                        />
                                    </span>{' '}
                                    <span>de {pageOptions.length}</span>
                                </span>
                            </div>
                            <BotoesPaginacaoNext />
                            <span style={{ margin: '3px 10px', fontSize: '1rem' }}>
                                Registros: {registroTotal(pageSize, pageIndex + 1, rows.length) + ' de ' + rows.length}
                            </span>
                        </div>
                    </div>
                </Fragment>
            ) : (
                <p className="sem-dados">Não há dados disponíveis</p>
            )}
        </div>
    );
}

export default Tabela;

function BotaoPaginacao(props) {
    const {
        className,
        style = {},
        buttonStyle = {},
        disabled = true,
        onClick,
        children
    } = props;

    return (
        <div className={className} style={style}>
            <Button onClick={onClick} disabled={disabled} style={buttonStyle}>
                {children}
            </Button>{' '}
        </div>
    );
}
