import React, { useEffect, useState } from 'react';
import { Col, Row, Modal, ModalBody, Form, Button, FormGroup } from 'reactstrap';
import { SelectForm, RadioForm, InputForm } from '../Basicos';
import { axiosApi } from '../Basicos/axiosInstances';


export default function ModalAssociar({
    setBloquearBotao, bloquearBotao, criticar, aula, modalAssociarEstaAberto, aulas, setModalAssociarEstaAberto, Verificacao, horarioAulaId, observacao,
    grupoIds, grupos, agregadorGrupo, handleAgregadorGrupo, horarioModal, handleGrupo, handleAulas, agregadorGruposOptions, updateServico, setAula,
    agregadorGruposValue, professores, handleProfessores, handleObservacao, local, locais, handleLocal, setInicio, inicio, filtroProfessores, tipoAtividades,
    setServico, servico, ativarAlert, setObservacao
}) {
    const [servicosOptions, setServicosOptions] = useState([]);
    const [grupoAlocadoId, setGrupoAlocadoId] = useState([]);
    const [professorServicoId, setProfessorServicoId] = useState();
    const [horarioAulaAtual, setHorarioAulaAtual] = useState();
    const [gruposDisponiveisServico, setGruposDisponiveisServico] = useState([]);
    const [gruposServicoAtual, setGruposServicoAtual] = useState([]);
    const [professoresServicoOptions, setProfessoresServicoOptions] = useState([]);
    const aulasOptions = () =>
        aulas
            .filter(aula => aula?.tipo_de_atividade_id === horarioModal?.tipo_atividade_id && aula?.tema_id === horarioModal?.tema_id)
            .map(aula => ({ value: aula?.id, label: aula?.sequencia_aula + "-" + aula?.titulo, professores: aula?.professores }));

    const aulasValue = () => aulasOptions().find(elemento => elemento.value === aula?.id);

    const professoresOptions = () => aulasValue() ? aulasValue().professores
        .map(professor => ({ value: professor.id, label: professor.nome }))
        : []

    const professoresOptionsServico = () => [{ value: 0, label: horarioAulaAtual?.professores[0].nome }]

    const professoresValue = () => professores?.map(id => {
        return professoresOptions().find(elemento => elemento.value === id);
    })

    const localOptions = () => locais.map(local => ({ value: local?.id, label: local?.nome }));

    const localValue = () => localOptions().find(elemento => elemento.value === local?.id);

    const grupoIdsOptions = () => grupos
        .filter(grupo => grupo.tipo_de_grupo_id === (aula != null ? agregadorGrupo : 3) && !grupoAlocadoId.includes(grupo.id))
        .map(grupo => ({ value: grupo.id, label: grupo.descricao }));

    const grupoIdsValue = () => grupoIdsOptions().filter(elemento => grupoIds.includes(elemento.value));

    const styleInputs = {
        col: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
        col2: { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 },
    }

    const tiposAtividadesServicos = tipoAtividades.filter(tp => tp.abreviatura == 'IPRA' || tp.abreviatura == 'VIV').map(tp => tp.id);

    const getServicos = async () => {
        try {
            const { data } = await axiosApi.get(`/auxiliares/servicos?tipo_atividade_id=${horarioModal.tipo_atividade_id}`);
            setServicosOptions(
                data.dados.map(el => ({ label: el.descricao, value: el.id }))
            )
            if (horarioModal?.tipo_atividade_id == 14 || horarioModal?.tipo_atividade_id == 72) {
                const infos = await axiosApi.get(`/professores/lista-de-professores`);
                setProfessoresServicoOptions(infos.data.dados.map(el => ({ label: el.nome, value: el.id })))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleGrupoServico = (input, value) => {
        let grupoRemovido = gruposServicoAtual.filter(item => !value.includes(item.value));

        setGruposDisponiveisServico(prevDisponiveis => [
            ...prevDisponiveis,
            ...grupoRemovido,
        ]);

        setGruposServicoAtual(prevAtuais =>
            prevAtuais.filter(item => value.includes(item.value))
        );

        let grupoAdicionado = gruposDisponiveisServico.find(
            item => value.includes(item.value) && !gruposServicoAtual.some(g => g.value === item.value)
        );

        if (grupoAdicionado) {
            setGruposServicoAtual(prevAtuais => [...prevAtuais, grupoAdicionado]);

            setGruposDisponiveisServico(prevDisponiveis =>
                prevDisponiveis.filter(item => item.value !== grupoAdicionado.value)
            );
        }
    }

    const getGrupos = async () => {
        try {
            const { data } = await axiosApi.get(`/grupos-disponiveis-servico?periodo_letivo_id=${horarioAulaAtual?.horario_periodo?.periodo_letivo_id}&servico_id=${horarioAulaAtual?.servico_id}&oferta_periodo_letivo_id=${horarioAulaAtual?.horario_periodo?.oferta_periodo_letivos_id}`)
            setGruposDisponiveisServico(data.map(elemento => ({ value: elemento.id, label: elemento.descricao })))
            setGruposServicoAtual(horarioAulaAtual.grupos_por_aula.map(elemento => ({ value: elemento.grupo_id, label: elemento.grupo.descricao })))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (modalAssociarEstaAberto) {
            setBloquearBotao(false)
            getServicos();
            const grupoAlocadoId = horarioModal.horarios_aulas.flatMap(ha => {
                if (ha.cancelada == false) {
                    if (ha.id == horarioAulaId) {
                        if (aula == null) {
                            const gruposDaAula = ha?.grupos_por_aula?.map(el => el.grupo)
                            handleGrupo(prev => gruposDaAula?.map(el => el.id))
                        }
                        setHorarioAulaAtual(ha)
                    }
                    if (ha.id != horarioAulaId) {
                        return ha.grupos_por_aula.map(ga => ga?.grupo_id);
                    }
                }
            });
            setGrupoAlocadoId(() => grupoAlocadoId);
        }
        else {
            //reseta valores ao fechar modal
            setServico();
            handleAgregadorGrupo(0);
            // handleGrupo(0); se funcionar ok remover
        }
    }, [modalAssociarEstaAberto]);

    useEffect(() => {
        if (horarioAulaAtual != undefined && horarioAulaAtual?.servico_id != null) {
            setProfessorServicoId(horarioAulaAtual.professores.map(professor => professor.id))
            getGrupos();
        }
    }, [horarioAulaAtual])

    const setServicoGetGrupos = async (value) => {
        setServico(value);
        const { data } = await axiosApi.get(`/grupos-disponiveis-servico?periodo_letivo_id=${horarioModal?.periodo_letivo_id}&servico_id=${value}&oferta_periodo_letivo_id=${horarioModal?.oferta_periodo_letivos_id}`)
        setGruposDisponiveisServico(data.map(elemento => ({ value: elemento.id, label: elemento.descricao })))
        // const { data } = await axiosApi.get(`/grupos-disponiveis-servico?periodo_letivo_id=${horarioModal?.periodo_letivo_id}&servico_id=${value}&oferta_periodo_letivo_id=${horarioModal?.oferta_periodo_letivos_id}`)
    }

    useEffect(() => {
        if (modalAssociarEstaAberto && !!aula) {
            //encontra os grupos da aula
            const gruposDaAula = horarioModal?.horarios_aulas?.find(el => el.id == horarioAulaId)?.grupos_por_aula?.map(el => el.grupo)
            //pega o tipo do grupo
            const tipoDeGrupo = gruposDaAula?.length && gruposDaAula[0].tipo_de_grupo_id
            //pega o servico da aula OBS so para Isec Pratico e Laboratorio Integrado
            const horario_aula = horarioModal.horarios_aulas?.find(el => el.aula.id == aula.id)

            //seta o servico
            setServico(horario_aula?.servico_id || undefined)
            //seta o tipo do grupo
            handleAgregadorGrupo(prev => tipoDeGrupo || 0)
            //seta os ids dos grupos
            handleGrupo(prev => gruposDaAula?.map(el => el.id))
        }
    }, [modalAssociarEstaAberto])

    // const criticar =  async() => {
    //     const response = await axiosApi.post(`/auxiliares/local-semestre`, horarioModal);
    //     console.log(response)
    // }

    const validaForm = () => {
        if (!!aulasValue && !!inicio) {
            if (aula != null)
                criticar(horarioModal)
            else {
                setHorarioAulaAtual(undefined);
                setServico(undefined);
                setGruposServicoAtual([]);
                setObservacao(undefined);
                setProfessorServicoId(undefined);
                let arraySomenteValores = gruposServicoAtual.map(item => item.value);
                updateServico(arraySomenteValores)
            }
        } else {
            ativarAlert(400, "Verifique campos obrigatórios")
        }
    }

    const professorServico = (input, value) => {
        setProfessorServicoId(value);
        handleProfessores(value)
    }

    const fecharModal = () => {
        setHorarioAulaAtual(undefined);
        setServico(undefined);
        setAula(undefined);
        setGruposServicoAtual([]);
        setObservacao(undefined);
        setProfessorServicoId(undefined);
        setModalAssociarEstaAberto(false);
    }

    return (
        <Modal isOpen={modalAssociarEstaAberto}>
            <ModalBody>
                <Row className="menu-modal-formal">
                    <Col sm={12} className="align-content-middle mb-2 mt-2">
                        <h5><b>Associar Aula 2</b></h5>
                    </Col>
                </Row>
                <Form>
                    <Row form>
                        {
                            horarioModal?.tipo_atividade_id == 14 || horarioModal?.tipo_atividade_id == 72 ?
                                <>
                                    <SelectForm
                                        name="servico_id"
                                        label="Serviço"
                                        value={servicosOptions.find(el => el.value == (servico == undefined ? horarioAulaAtual?.servico_id : servico))}
                                        options={servicosOptions}
                                        onChange={(input, value) => setServicoGetGrupos(value)}
                                        colunaClass="px-0"
                                        style={{ col: { ...styleInputs.col } }}
                                        isRequired={true}
                                    />
                                    <SelectForm
                                        isMulti
                                        name="professor_id"
                                        label="Professor"
                                        value={professoresServicoOptions.find(el => el.value == professorServicoId)}
                                        options={professoresServicoOptions}
                                        onChange={(input, value) => professorServico(input, value)}
                                        colunaClass="px-0"
                                        isRequired={true}
                                        style={{ col: { ...styleInputs.col } }}
                                    />
                                </>
                                :
                                <>
                                    <SelectForm
                                        name="aula_id"
                                        label="Aula"
                                        value={aulasValue()}
                                        options={aulasOptions()}
                                        onChange={(input, value) => handleAulas(value)}
                                        colunaClass="px-0"
                                        isRequired={true}
                                        style={{ col: { ...styleInputs.col } }}
                                    />
                                    <SelectForm
                                        isMulti
                                        name="professor_id"
                                        label="Professor"
                                        value={professoresValue()}
                                        options={professoresOptions()}
                                        onChange={(input, value) => handleProfessores(value)}
                                        colunaClass="px-0"
                                        isRequired={true}
                                        style={{ col: { ...styleInputs.col } }}
                                    />
                                </>
                        }
                        {/* </>
                                :
                                <>
                                    <SelectForm
                                        name="servico_id"
                                        label="Serviço"
                                        value={servicosOptions.find(el => el.value == horarioAulaAtual?.rodizio?.servico_id)}
                                        options={servicosOptions}
                                        onChange={(input, value) => setServico(value)}
                                        colunaClass="px-0"
                                        style={{ col: { ...styleInputs.col } }}
                                        isRequired={true}
                                        readOnly={true}
                                    />
                                    <SelectForm
                                        isMulti
                                        name="professor_id"
                                        label="Professor"
                                        value={[{ value: 0, label: horarioAulaAtual?.professores[0].nome }]}
                                        options={professoresOptionsServico()}
                                        onChange={(input, value) => handleProfessores(value)}
                                        colunaClass="px-0"
                                        isRequired={true}
                                        style={{ col: { ...styleInputs.col } }}
                                        readOnly={true}
                                    />
                                </>
                        } */}
                        <SelectForm
                            name="local_id"
                            label="Local"
                            value={localValue() ?? { label: 'Selecione', value: 'Selecione' }}
                            options={localOptions()}
                            onChange={(input, value) => handleLocal(value)}
                            colunaClass="px-0"
                            isRequired={true}
                            style={{ col: { ...styleInputs.col } }}
                        />
                        {
                            horarioModal?.tipo_atividade_id == 14 || horarioModal?.tipo_atividade_id == 72 ?
                                "" : <>
                                    {
                                        tiposAtividadesServicos.includes(horarioModal?.tipo_atividade_id) &&
                                        <SelectForm
                                            name="servico_id"
                                            label="Serviço"
                                            value={servicosOptions.find(el => el.value == servico)}
                                            options={servicosOptions}
                                            onChange={(input, value) => setServico(value)}
                                            colunaClass="px-0"
                                            style={{ col: { ...styleInputs.col } }}
                                            isRequired={true}
                                        />
                                    }
                                </>
                        }
                        <InputForm
                            type="text"
                            label="Duração"
                            name="duracao_minima"
                            value={aula?.duracao_minima != undefined ? `${aula.duracao_minima}h` : ''}
                            onChange={(e) => console.log(e.target.name)}
                            colunaClass="px-0"
                            isRequired={true}
                            style={{ col: { ...styleInputs.col2 } }}
                            readOnly
                        />
                        <InputForm
                            type="time"
                            label="Inicio"
                            name="inicio"
                            id="inicio"
                            value={inicio}
                            onChange={(index, value) => setInicio(value)}
                            colunaClass="px-0"
                            style={{ col: { ...styleInputs.col2 } }}
                            isRequired={true}
                        />
                        <RadioForm
                            name="grupo_agregador_id"
                            label="Grupo de Alunos"
                            value={agregadorGruposValue()}
                            options={agregadorGruposOptions()}
                            valorPadrao={aula != null ? agregadorGrupo.toString() : "3"}
                            onChange={(input, value) => handleAgregadorGrupo(parseInt(value))}
                            style={{ col: { ...styleInputs.col } }}
                        />
                        {
                            agregadorGrupo !== 0 || aula == null
                                ?
                                horarioModal?.tipo_atividade_id == 14 || horarioModal?.tipo_atividade_id == 72 ?
                                    <SelectForm
                                        name="grupos_ids"
                                        label="Grupos"
                                        value={gruposServicoAtual}
                                        options={gruposDisponiveisServico}
                                        onChange={(input, value) => handleGrupoServico(input, value)}
                                        isMulti
                                        colunaClass="px-0"
                                        style={{ col: { ...styleInputs.col } }}
                                        isRequired={true}
                                    /> : <SelectForm
                                        name="grupos_ids"
                                        label="Grupos"
                                        value={grupoIdsValue()}
                                        options={grupoIdsOptions()}
                                        onChange={(input, value) => handleGrupo(value)}
                                        isMulti
                                        colunaClass="px-0"
                                        style={{ col: { ...styleInputs.col } }}
                                        isRequired={true}
                                    />
                                : null
                        }
                        <InputForm
                            type="textarea"
                            label="Observação"
                            name="observacao"
                            id="observacao"
                            value={observacao}
                            onChange={(index, value) => handleObservacao(value)}
                            colunaClass="px-0"
                            style={{ col: { ...styleInputs.col } }}
                            isRequired={true}
                        />
                        <Row className="contagem-caracteres">
                            {observacao?.length}/255
                        </Row>
                    </Row>
                </Form>
                <Col sm={12} className="align-content-middle mb-3 p-0">
                    <Button
                        onClick={() => fecharModal()}
                        className="float-right btn-transparente"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={bloquearBotao ? '' : () => validaForm()} className="float-right">Salvar</Button>
                </Col>
            </ModalBody>
        </Modal>
    );
}
