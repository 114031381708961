import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button, Nav, TabContent, TabPane, Table } from 'reactstrap';
import { connect } from 'react-redux';
import * as actionsRedux from '../../../Store/Actions/index';
import InputForm from '../../Form/InputForm';
import SelectForm from '../../Form/SelectForm';
import { axiosApi } from '../../axiosInstances';
import './style.css';
import NewLoader from '../../NewLoader';
import NavItemCustom from '../../navItem';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import ModalAtualizarAula from '../../../Modal/ModalAtualizarAula';

const ModalConfiguracaoRodizio = ({
    isOpen,
    setIsOpen,
    ativar,
    setConfiguracoesRodizio,
    configuracaoRodizioId
}) => {
    const style = {
        col2: { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 },
        col4: { xs: 6, md: 3, sm: 3, lg: 3, xl: 3 },
        col5: { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 },
        col6: { xs: 6, md: 2, sm: 2, lg: 2, xl: 2 }
    };
    const campos = {
        semestre_id: 'Semestre',
        turma_id: 'Turma',
        tipo_rodizio_id: 'Tipo de Rodízio',
        servico_id: 'Serviço',
        local_id: 'Local',
        professor_id: 'Professor',
        ciclo_id: 'Ciclo',
        data_inicio: 'Data de Início',
        data_fim: 'Data de Fim',
        local_aluno: 'Local do Aluno',
        quantidade_alunos: 'Quantidade de Alunos',
        quantidade_rodizio_aluno: 'Quantidade de Rodízio por Aluno',
        manter_grupo_aluno: 'Manter Grupo de Aluno'
    };
    const campos2 = {
        dia_semana: 'Dia da Semana',
        horario_id: 'Horário',
        hora_inicio: 'Hora de Início',
        duracao_minima: 'Duração'
    };

    const [tab, setTab] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [qtdCampos, setQtdCampos] = useState(1);
    const [servicosOptions, setServicosOptions] = useState([]);
    const [semestresOptions, setSemestresOptions] = useState([]);
    const [turmasOptions, setTurmasOptions] = useState([]);
    const [ciclosOptions, setCiclosOptions] = useState([]);
    const [diasSemanaOptions, setDiasSemanaOptions] = useState([]);
    const [horariosOptions, setHorariosOptions] = useState([]);
    const [locaisOptions, setLocaisOptions] = useState([]);
    const [professoresOptions, setProfessoresOptions] = useState([]);
    const [tipoRodizioOptions, setTipoRodizioOptions] = useState([]);
    const [duracaoOptions, setDuracaoOptions] = useState([]);
    const [dados, setDados] = useState({
        semestre_id: undefined,
        turma_id: undefined
    });
    const [modalSalvar, setModalSalvar] = useState(false);
    const [dados2, setDados2] = useState([]);

    const toggle = () => {
        setIsLoading(false)
        setDados({
            semestre_id: undefined,
            turma_id: undefined,
            qtd_alunos: 0
        });
        setDados2([]);
        setTab(1);
        setIsOpen(!isOpen);
    };

    const alterTab = (value) => {
        setTab(value);
    }

    const onChange = (nome, valor, indice, ultimoValor, action) => {
        switch (nome) {
            case 'semestre_id':
                setDados({ ...dados, semestre_id: valor });
                getTurmas(valor);
                break;
            case 'turma_id':
                setDados({ ...dados, turma_id: valor });
                break;
            case 'tipo_rodizio_id':
                setDados({ ...dados, tipo_rodizio_id: valor });
                getCiclos();
                break;
            case 'servico_id':
                setDados({ ...dados, servico_id: valor });
                break;
            case 'local_id':
                setDados({ ...dados, local_id: valor });
                break;
            case 'professor_id':
                setDados({ ...dados, professor_id: valor });
                break;
            case 'ciclo_id':
                setDados({ ...dados, ciclo_id: valor });
                getServicos(valor)
                break;
            case 'data_inicio':
                setDados({ ...dados, data_inicio: valor });
                break;
            case 'data_fim':
                setDados({ ...dados, data_fim: valor });
                break;
            case 'local_aluno':
                setDados({ ...dados, local_aluno: valor });
                break;
            case 'quantidade_alunos':
                setDados({ ...dados, quantidade_alunos: valor });
                break;
            case 'quantidade_rodizio_aluno':
                setDados({ ...dados, quantidade_rodizio_aluno: valor });
                break;
            case 'manter_grupo_aluno':
                setDados({ ...dados, manter_grupo_aluno: valor });
                break;

            case 'dia_semana':
                setDados2(prevState => {
                    const novoEstado = [...prevState];
                    novoEstado[indice] = { ...novoEstado[indice], dia_semana: valor };
                    return novoEstado;
                })
                break;
            case 'horario_id':
                setDados2(prevState => {
                    const novoEstado = [...prevState];
                    novoEstado[indice] = { ...novoEstado[indice], horario_id: valor };
                    return novoEstado;
                })
                break;
            case 'hora_inicio':
                setDados2(prevState => {
                    const novoEstado = [...prevState];
                    novoEstado[indice] = { ...novoEstado[indice], hora_inicio: valor };
                    return novoEstado;
                })
                break;
            case 'duracao_minima':
                setDados2(prevState => {
                    const novoEstado = [...prevState];
                    novoEstado[indice] = { ...novoEstado[indice], duracao_minima: valor };
                    return novoEstado;
                })
                break;
            default:
                break;
        }
    };

    const getServicos = async (cicloId) => {
        try {
            const { data } = await axiosApi.get(`/auxiliares/servicos-ciclo?ciclo_id=${cicloId}`);
            setServicosOptions(data.map(element => ({ label: element.descricao, value: element.id })));
        } catch (error) {
            console.log(error);
        }
    }

    const getCiclos = async () => {
        try {
            const { data } = await axiosApi.get(`/auxiliares/ciclos-semestre?semestre_id=${dados.semestre_id}&turma_id=${dados.turma_id}`);
            setCiclosOptions(data.map(element => ({ label: element.titulo, value: element.id })));
        } catch (error) {
            console.log(error);
        }
    }

    const getTurmas = async (semestreId) => {
        try {
            const { data } = await axiosApi.get(`/auxiliares/turmas-semestre?semestre_id=${semestreId}`);
            setTurmasOptions(data.map(element => ({ label: element.descricao, value: element.id })));
        } catch (error) {
            console.log(error);
        }
    };

    const onChangeCampos = (evento, index) => {
        let campos = 0;
        if (evento == "mais") {
            campos = qtdCampos + 1;
        }
        if (evento == "menos") {
            if (qtdCampos == 1) {
                campos = 1;
                setDados2([]);
            } else {
                campos = qtdCampos - 1;
                dados2.splice(index, 1);
                setDados2(dados2);
            }
        }
        setQtdCampos(campos);
    }

    const openModalSalvar = async () => {
        let isValid = true
        for (const [campo, descricao] of Object.entries(campos)) {
            if (dados[campo] == null) {
                if (descricao == "Quantidade de Rodízio por Aluno" && dados.tipo_rodizio_id == 2) {
                    isValid = true;
                } else {
                    ativar(400, `Campo ${descricao} vazio!`);
                    isValid = false;
                    break;
                }
            }
        }

        if (!isValid)
            return;

        for (const [index, item] of dados2.entries()) {
            for (const [campo, descricao] of Object.entries(campos2)) {
                if (item[campo] == null) {
                    ativar(400, `O campo ${descricao} está ausente no item ${index + 1}!`);
                    isValid = false;
                    break;
                }
            }
            if (!isValid) break;
        }

        if (!isValid)
            return;

        if (configuracaoRodizioId != null)
            setModalSalvar(true);
        else
            salvar(false);
    }

    const salvar = async (atualizaHoje) => {
        try {
            let infos = dados;
            infos.itens = dados2;
            infos.atualizar_hoje = atualizaHoje;
            if (configuracaoRodizioId != null) {
                const response = await axiosApi.put(`/auxiliares/configuracao-do-rodizio/${configuracaoRodizioId}`, infos);
                ativar(200, "Configuração de Rodízio editado com sucesso!");
                setConfiguracoesRodizio(prevConfiguracao =>
                    prevConfiguracao.map(configuracao =>
                        configuracao.id === response.data.data.id ? response.data.data : configuracao
                    )
                );
            } else {
                const response = await axiosApi.post('/auxiliares/configuracao-do-rodizio', infos);
                ativar(200, "Configuração de Rodízio adicionado com sucesso!");
                setConfiguracoesRodizio(prevConfiguracoes => [...prevConfiguracoes, response.data.data]);
            }
        } catch (error) {
            ativar(400, error?.response?.data?.erro);
            console.log(error.response);
        } finally {
            setModalSalvar(false);
            toggle();
        }
    }

    const getFiltros = async () => {
        try {
            let rota = configuracaoRodizioId != null ? `${configuracaoRodizioId}/edit` : 'create';

            const { data } = await axiosApi.get(`/auxiliares/configuracao-do-rodizio/${rota}`);
            if (configuracaoRodizioId != null) {
                setServicosOptions(data.servicos.map(element => ({ label: element.descricao, value: element.id })));
                setCiclosOptions(data.ciclos.map(element => ({ label: element.titulo, value: element.id })));
                setTurmasOptions(data.turmas.map(element => ({ label: element.descricao, value: element.id })));
                setDados(data.configuracao)
                setDados2(data.configuracao.horarios_rodizio)
                setQtdCampos(data.configuracao.horarios_rodizio.length == 0 ? 1 : data.configuracao.horarios_rodizio.length)
            }
            setSemestresOptions(data.semestres.map(element => ({ label: element.descricao, value: element.id })));
            setDiasSemanaOptions(data.dias_semana.map(element => ({ label: element.label, value: element.value })));
            setDuracaoOptions(data.duracao.map(element => ({ label: element.label, value: element.value })));
            setHorariosOptions(data.horarios.map(element => ({ label: element.descricao, value: element.id })));
            setLocaisOptions(data.locais.map(element => ({ label: element.nome, value: element.id })));
            setProfessoresOptions(data.professores.map(element => ({ label: element.nome, value: element.id })));
            setTipoRodizioOptions(data.tipo_rodizio.map(element => ({ label: element.descricao, value: element.id })));
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getFiltros();
    }, [isOpen]);

    return (
        // <NewLoader isActive={isLoading} overlay >
        <Modal isOpen={isOpen} toggle={toggle} size='lg'>
            <ModalAtualizarAula isOpen={modalSalvar} atualizarAula={salvar} />
            <ModalHeader className="menu-modal-formal">
                <h2>
                    <b>{configuracaoRodizioId != null ? "Editar" : "Adicionar"} CONFIGURAÇÃO DE RODÍZIO</b>
                </h2>
                <Nav tabs className="tabs-ciclo">
                    <NavItemCustom tabs classNamePai="tab-pai" classname="tabs-item-ciclo" activeTab={tab}
                        onClick={() => alterTab(1)}
                        descricao={"Geral"}
                        id={1}
                    />
                    <NavItemCustom tabs classNamePai="tab-pai" classname="tabs-item-ciclo" activeTab={tab}
                        onClick={() => alterTab(2)}
                        descricao={"Horários"}
                        id={2}
                    />
                </Nav>
            </ModalHeader>
            <ModalBody>
                <TabContent activeTab={tab}>
                    <TabPane tabId={1}>
                        <Table bordered striped>
                            <Row className="modal-grupos-aluno">
                                <SelectForm
                                    required={true}
                                    label="Semestre"
                                    name="semestre_id"
                                    placeholder="Selecione"
                                    options={semestresOptions}
                                    value={semestresOptions?.find(option => option.value === dados.semestre_id) || ''}
                                    onChange={onChange}
                                    style={{ col: style.col3 }}
                                />
                                <SelectForm
                                    required={true}
                                    label="Turma"
                                    name="turma_id"
                                    placeholder="Selecione"
                                    options={turmasOptions}
                                    value={turmasOptions?.find(option => option.value === dados.turma_id) || ''}
                                    onChange={onChange}
                                    style={{ col: style.col3 }}
                                />
                                <SelectForm
                                    required={true}
                                    label="Tipo de Rodízio"
                                    name="tipo_rodizio_id"
                                    placeholder="Selecione"
                                    options={tipoRodizioOptions}
                                    value={tipoRodizioOptions?.find(option => option.value === dados.tipo_rodizio_id) || ''}
                                    onChange={onChange}
                                    style={{ col: style.col3 }}
                                />
                                {
                                    dados.tipo_rodizio_id != undefined ?
                                        <>
                                            <SelectForm
                                                label="Ciclo"
                                                name="ciclo_id"
                                                placeholder="Selecione"
                                                options={ciclosOptions}
                                                value={ciclosOptions?.find(option => option.value === dados.ciclo_id) || ''}
                                                onChange={onChange}
                                            />
                                            <SelectForm
                                                label="Serviço"
                                                name="servico_id"
                                                placeholder="Selecione"
                                                options={servicosOptions}
                                                value={servicosOptions?.find(option => option.value === dados.servico_id) || ''}
                                                onChange={onChange}
                                            />
                                        </> : ""
                                }

                                <SelectForm
                                    label="Local"
                                    name="local_id"
                                    placeholder="Selecione"
                                    options={locaisOptions}
                                    value={locaisOptions?.find(option => option.value === dados.local_id) || ''}
                                    onChange={onChange}
                                />
                                <InputForm
                                    required={true}
                                    type="date"
                                    name="data_inicio"
                                    label="Data Início"
                                    onChange={onChange}
                                    value={dados?.data_inicio ?? ""}
                                    style={{ col: style.col2 }}
                                />
                                <InputForm
                                    required={true}
                                    type="date"
                                    name="data_fim"
                                    label="Data Fim"
                                    onChange={onChange}
                                    value={dados?.data_fim ?? ""}
                                    style={{ col: style.col2 }}
                                />
                                <SelectForm
                                    label="Professor"
                                    name="professor_id"
                                    placeholder="Selecione"
                                    options={professoresOptions}
                                    value={professoresOptions?.find(option => option.value === dados.professor_id) || ''}
                                    onChange={onChange}
                                />
                                <InputForm
                                    required={true}
                                    type="text"
                                    name="local_aluno"
                                    label="Local do Aluno"
                                    onChange={onChange}
                                    value={dados?.local_aluno ?? ""}
                                    style={{ col: style.col2 }}
                                />
                                <Col md={6} className=" modal-configuracao">
                                    <h6>Manter Mesmo Grupo de Alunos</h6>
                                    <Row className="radio-ciclo">
                                        <Col md={4}>
                                            <input
                                                type="radio"
                                                name={`manter_grupo_aluno`}
                                                onChange={(e) => onChange('manter_grupo_aluno', e.target.value)}
                                                value="true"
                                                checked={dados.manter_grupo_aluno == "true" || dados.manter_grupo_aluno == true} />{' '}Sim
                                        </Col>
                                        <Col md={4}>
                                            <input
                                                type="radio"
                                                name={`manter_grupo_aluno`}
                                                onChange={(e) => onChange('manter_grupo_aluno', e.target.value)}
                                                value="false"
                                                checked={dados.manter_grupo_aluno == "false" || dados.manter_grupo_aluno == false}
                                            />{' '}Não
                                        </Col>
                                    </Row>
                                </Col>
                                {dados.tipo_rodizio_id == 1 ? <>
                                    <InputForm
                                        required={true}
                                        type="number"
                                        name="quantidade_alunos"
                                        label="Quantidade de Alunos"
                                        onChange={onChange}
                                        value={dados?.quantidade_alunos ?? ""}
                                        style={{ col: style.col2 }}
                                    />
                                    <InputForm
                                        required={true}
                                        type="number"
                                        name="quantidade_rodizio_aluno"
                                        label="Quantidade de Rodízios por Aluno"
                                        onChange={onChange}
                                        value={dados?.quantidade_rodizio_aluno}
                                        style={{ col: style.col2 }}
                                    />
                                </> : <InputForm
                                    required={true}
                                    type="number"
                                    name="quantidade_alunos"
                                    label="Quantidade de Alunos"
                                    onChange={onChange}
                                    value={dados?.quantidade_alunos ?? ""}
                                />}

                            </Row>
                        </Table>
                    </TabPane>

                    <TabPane className="itens-ciclo" tabId={2}>
                        <Table bordered striped>
                            {Array.from({ length: qtdCampos }, (_, index) => (
                                <Row className="modal-grupos-aluno modal-ciclo">
                                    <Col md={10} className="modal-configuracao-tab-2">
                                        <SelectForm
                                            className="form-configuracao"
                                            indice={index}
                                            label="Dia da Semana"
                                            name="dia_semana"
                                            placeholder="Selecione"
                                            options={diasSemanaOptions}
                                            value={diasSemanaOptions?.find(option => option.value === dados2?.[index]?.dia_semana) || ''}
                                            onChange={onChange}
                                            style={{ col: style.col4 }}
                                        />
                                        <SelectForm
                                            className="form-configuracao"
                                            indice={index}
                                            label="Horário"
                                            name="horario_id"
                                            placeholder="Selecione"
                                            options={horariosOptions}
                                            value={horariosOptions?.find(option => option.value === dados2?.[index]?.horario_id) || ''}
                                            onChange={onChange}
                                            style={{ col: style.col4 }}
                                        />
                                        <InputForm
                                            required={true}
                                            indice={index}
                                            type="time"
                                            name="hora_inicio"
                                            label="Hora Início"
                                            onChange={onChange}
                                            value={dados2?.[index]?.hora_inicio}
                                            style={{ col: style.col4 }}
                                        />
                                        <SelectForm
                                            className="form-configuracao"
                                            indice={index}
                                            label="Duração"
                                            name="duracao_minima"
                                            placeholder="Selecione"
                                            options={duracaoOptions}
                                            value={duracaoOptions?.find(option => option.value === dados2?.[index]?.duracao_minima) || ''}
                                            onChange={onChange}
                                            style={{ col: style.col4 }}
                                        />
                                    </Col>
                                    <Col md={2} className="botoes-modal-configuracao">
                                        {qtdCampos > 1 && index == 0
                                            || qtdCampos > 1 && index != qtdCampos - 1 ? "" : <FiPlus onClick={() => onChangeCampos("mais", index)} className="menuIconsReplica " />}
                                        <FiTrash2 onClick={() => onChangeCampos("menos", index)} className="menuIconsReplica" />
                                    </Col>
                                </Row>

                            ))}
                        </Table>
                    </TabPane>
                </TabContent>

                <Row>
                    <Col className="align-content-middle">
                        <Button className="mx-3" onClick={() => toggle()}>Cancelar</Button>
                        <Button className="mx-3" onClick={() => openModalSalvar()}>Salvar</Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
        // </NewLoader>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao))
    };
};

export default connect(null, mapDispatchToProps)(ModalConfiguracaoRodizio);
